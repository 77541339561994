import Grid from '@mui/material/Grid';
import { ReactNode } from 'react';
import { useTheme } from '@mui/material';

export type Value = string | number | boolean | null | object | Record<string, unknown>;

export type ValueMapper = (value: Value) => ReactNode;

export interface DataGridMasterDetailField {
  formattedName: string;
  fieldName: string;
  valueMapper?: ValueMapper;
}

interface DataGridRowMasterDetailProps {
  row: {
    [key: string]: Value;
  };
  fields: DataGridMasterDetailField[];
}

export const renderMasterDetail = ({ row, fields }: DataGridRowMasterDetailProps) => {
  // Filter out fields that have no content
  const availableFields = fields.filter((field) => row[field.fieldName] !== undefined);

  // Check row has any of the fields to render
  if (availableFields.length === 0) {
    return undefined;
  }

  return <DataGridMasterDetail row={row} fields={availableFields} />;
};

export const DataGridMasterDetail = ({ row, fields }: DataGridRowMasterDetailProps) => {
  const theme = useTheme();

  return (
    <Grid
      container
      spacing={2}
      sx={{
        color: theme.palette.text.secondary,
        p: 4,
        backgroundColor: theme.palette.background.default,
        borderBottom: `1px solid ${theme.palette.divider}`,
      }}
    >
      {fields.map((field) => {
        const value = row[field.fieldName];

        return (
          <Grid item xs={12} lg={6} key={field.formattedName} container sx={{ pt: 2 }}>
            <Grid container>
              <Grid item xs={2}>
                {field.formattedName}:
              </Grid>
              <Grid item xs={10} sx={{ pl: 4, pr: 6 }}>
                {field.valueMapper ? field.valueMapper(value) : <>value</>}
              </Grid>
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
};
