import { default as MuiTable } from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import Paper from '@mui/material/Paper';
import { Box, CircularProgress, Stack, TableSortLabel, Typography } from '@mui/material';
import { Pagination } from './components/Pagination';
import { useTranslation } from 'react-i18next';
import { useTable, UseTableProps } from './useTable';
import { Footer, StyledTableCell } from './components/Styled';
import { SearchInput } from './components/SearchInput';
import { CollapsibleTableRow } from './CollapsibleTableRow';

export type TableRowLabelFunction = (options: {
  rowOpen: boolean;
  toggleRowOpen: () => void;
}) => React.ReactNode;
export type ComplexValueType = {
  label?: React.ReactNode | TableRowLabelFunction;
  value?: string | number;
  textValue?: string;
};
export type ValueType = React.ReactNode | number | ComplexValueType | null | undefined;
export type RowDataType = Record<string | number | symbol, ValueType> & {
  _id: string | number;
};
export interface TableProps<T> extends UseTableProps<T> {
  noDataCharacter?: string;
  translationKey?: string;
  itemsPerPage?: number;
  searchable?: boolean;
  muted?: boolean;
  embedded?: boolean;
  loading?: boolean;
  searchPlaceholder?: string;
  omittedSortKeys?: (keyof T)[];
  footerRenderer?: () => JSX.Element;
}

export function Table<T extends RowDataType>({
  data,
  omittedKeys,
  omittedSortKeys = ['actions'],
  translationKey,
  noDataCharacter = '–',
  itemsPerPage = 10,
  searchable = false,
  onRowClick,
  nestedData,
  onActionClick,
  onActionMenuClick,
  muted = false,
  embedded = false,
  loading = false,
  actionMenu,
  searchPlaceholder,
  defaultOrderBy,
  defaultOrder,
}: TableProps<T>) {
  const {
    showPagination,
    presentationalData,
    columnLabels,
    items,
    skip,
    take,
    total,
    handleRowClick,
    handleSearchChange,
    nestedTableData,
    orderBy,
    order,
    getSortHandler,
  } = useTable({
    itemsPerPage,
    data,
    omittedKeys,
    onRowClick,
    nestedData,
    onActionClick,
    onActionMenuClick,
    actionMenu,
    defaultOrderBy,
    defaultOrder,
  });

  const { t } = useTranslation(translationKey);

  return (
    <Stack direction='column' spacing={embedded ? 0 : 4}>
      {searchable && (
        <SearchInput
          placeholder={searchPlaceholder ?? t('viper::search')}
          onChange={handleSearchChange}
          sx={embedded ? { my: 2, mx: 3 } : undefined}
        />
      )}
      <TableContainer
        component={embedded ? Box : Paper}
        sx={
          embedded
            ? {
                borderTop: '1px solid',
                borderBottom: '1px solid',
                borderTopColor: (theme) => theme.palette.divider,
                borderBottomColor: (theme) => theme.palette.divider,
              }
            : undefined
        }
      >
        {' '}
        {loading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              minHeight: '10rem',
            }}
            data-testid='loading'
          >
            <CircularProgress />
          </Box>
        ) : (
          <MuiTable data-testid='table'>
            <TableHead>
              <TableRow>
                {columnLabels.map((columnLabel) => {
                  const label = columnLabel.startsWith('_') ? '' : t(columnLabel);
                  return (
                    <StyledTableCell
                      sx={
                        muted
                          ? {
                              borderBottom: '1px solid',
                              borderBottomColor: (theme) => theme.palette.divider,
                            }
                          : undefined
                      }
                      data-testid='th-cell'
                      key={columnLabel}
                      $alignRight={columnLabel === actionMenu?.label}
                      $muted={muted}
                      sortDirection={orderBy === columnLabel ? order : false}
                    >
                      {omittedSortKeys?.includes(columnLabel) ? (
                        label
                      ) : (
                        <TableSortLabel
                          active={orderBy === columnLabel}
                          direction={orderBy === columnLabel ? order : 'asc'}
                          onClick={getSortHandler(columnLabel)}
                          sx={
                            muted
                              ? {
                                  '&.Mui-active': {
                                    color: (theme) => theme.palette.text.secondary,
                                  },
                                  '&:hover': {
                                    color: (theme) => theme.palette.text.secondary,
                                    opacity: 0.8,
                                  },
                                }
                              : {
                                  '&.Mui-active': {
                                    color: (theme) => theme.palette.primary.contrastText,
                                  },
                                  '&:hover': {
                                    color: (theme) => theme.palette.primary.contrastText,
                                    opacity: 0.8,
                                  },
                                }
                          }
                        >
                          {label}
                        </TableSortLabel>
                      )}
                    </StyledTableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {presentationalData?.map((row, rowIndex) => {
                const currentNestedTableData = nestedTableData?.find((field) => {
                  return field.find((r) => {
                    return r.name === '_id' && r.initialValue === row._id;
                  });
                });
                return (
                  <CollapsibleTableRow
                    key={row._id}
                    nestedFields={currentNestedTableData}
                    row={row}
                    omittedKeys={omittedKeys as string[]}
                    noDataCharacter={noDataCharacter}
                    columnLabels={columnLabels}
                    handleRowClick={handleRowClick}
                    actionMenu={actionMenu}
                    onActionMenuClick={onActionMenuClick}
                    onActionClick={onActionClick}
                    rowIndex={rowIndex}
                  />
                );
              })}
            </TableBody>
          </MuiTable>
        )}
        {showPagination && (
          <Footer>
            <Typography sx={{ mr: 2, fontSize: '14px', color: 'inherit' }}>
              {total > 0 && t('table::footer-results', { skip: skip + 1, take, total })}
              {total === 0 && t('table::footer-empty-assets')}
            </Typography>
            <Pagination items={items} />
          </Footer>
        )}
      </TableContainer>
    </Stack>
  );
}
