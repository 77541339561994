import React, { CSSProperties, useEffect, useState } from 'react';
import { FileUploadState, isActiveUpload, useUpload } from './useUpload';
import {
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from '@mui/material';
import {
  Clear,
  Close,
  Done,
  KeyboardArrowDown,
  KeyboardArrowUp,
  Refresh,
  WarningAmber,
} from '@mui/icons-material';
import { ClipLoader } from 'react-spinners';
import { useTranslation } from 'react-i18next';
import { useBeforeUnload } from 'react-router-dom';
import { Tooltip } from '../Tooltip';

const override: CSSProperties = {
  border: '4px solid',
};

const LoadingSpinner: React.FC = () => {
  const { t } = useTranslation('v-log-upload');

  const theme = useTheme();
  return (
    <Box position='relative' display='flex'>
      <ClipLoader
        size={25}
        aria-label={t('loading')}
        color={theme.palette.primary.main}
        cssOverride={override}
      />
    </Box>
  );
};

export const UploadIndicator: React.FC = () => {
  const { files, clearList, retry } = useUpload();
  const { t } = useTranslation('v-log-upload');
  const [minimized, setMinimized] = useState(false);
  const [lastListSize, setLastListSize] = useState(0);
  const theme = useTheme();

  useEffect(() => {
    const listSize = Object.keys(files).length;
    if (listSize > lastListSize) {
      setMinimized(false);
    }
    setLastListSize(listSize);
  }, [lastListSize, files]);

  const hasActiveUploads = !!Object.values(files).find((file) => isActiveUpload(file));
  const hasErroredUploads = !!Object.values(files).find(
    (file) => file.state === FileUploadState.ERRORED
  );

  useBeforeUnload((event) => {
    if (hasActiveUploads) {
      event.preventDefault();
      return (event.returnValue = '');
    }
  });

  if (Object.keys(files).length === 0) {
    return null;
  }

  return (
    <Box
      bgcolor='white'
      borderRadius='6px 6px 0 0'
      position='fixed'
      left={8}
      bottom='0'
      width={280 - 16}
      zIndex={1}
      boxShadow='0 0 10px rgba(0, 0, 0, 0.5)'
    >
      <Box
        display='flex'
        flexDirection='row'
        padding='0.5rem 1rem 0.5rem'
        justifyContent='space-between'
        bgcolor={theme.palette.grey[300]}
        borderRadius='6px 6px 0 0'
        alignItems='center'
      >
        <Typography fontSize='14px' color={theme.palette.text.secondary}>
          Uploads
        </Typography>
        <Box display='flex' flexDirection='row' alignItems='center'>
          {minimized && (
            <>
              {hasErroredUploads ? (
                <Tooltip title={t('error-list')}>
                  <WarningAmber sx={{ color: theme.palette.error.main }} />
                </Tooltip>
              ) : hasActiveUploads ? (
                <LoadingSpinner />
              ) : (
                <Tooltip title={t('done-list')}>
                  <Done sx={{ color: theme.palette.primary.main }} />
                </Tooltip>
              )}
              <Box
                sx={{
                  paddingLeft: '0.5rem',
                }}
              />
            </>
          )}
          <Tooltip title={minimized ? t('restore') : t('minimize')}>
            <IconButton
              onClick={() => {
                setMinimized((value) => !value);
              }}
            >
              {minimized ? (
                <KeyboardArrowUp
                  sx={{ color: (theme) => theme.palette.text.secondary, fontSize: 20 }}
                />
              ) : (
                <KeyboardArrowDown
                  sx={{ color: (theme) => theme.palette.text.secondary, fontSize: 20 }}
                />
              )}
            </IconButton>
          </Tooltip>
          {!minimized && !hasActiveUploads && (
            <Tooltip title={t('clear')}>
              <IconButton
                onClick={() => {
                  if (!hasActiveUploads) {
                    clearList();
                  }
                }}
              >
                <Clear sx={{ color: (theme) => theme.palette.text.secondary, fontSize: 22 }} />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </Box>
      {minimized ? null : (
        <Box maxHeight='calc(100vh - 200px)' overflow='visible auto'>
          <List sx={{ padding: 0 }}>
            {Object.entries(files).map(([key, file], index) => (
              <React.Fragment key={key}>
                {index > 0 ? <Divider color={theme.palette.grey[500]} /> : null}
                <ListItem key={key} sx={{ minHeight: '2.5rem' }}>
                  <ListItemText
                    disableTypography // so that the ellipsis works
                    sx={{
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      fontSize: 14,
                      color: theme.palette.text.secondary,
                    }}
                  >
                    {file.name}
                  </ListItemText>
                  <Box
                    sx={{
                      paddingLeft: '0.5rem',
                    }}
                  />
                  {file.state === FileUploadState.ERRORED ? (
                    <>
                      <Tooltip title={t('retry')}>
                        <IconButton
                          onClick={() => {
                            retry(key);
                          }}
                        >
                          <Refresh
                            sx={{
                              color: (theme) => theme.palette.text.secondary,
                              fontSize: 18,
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={t('error')}>
                        <WarningAmber sx={{ color: theme.palette.error.main }} />
                      </Tooltip>
                    </>
                  ) : file.state === FileUploadState.UPLOADED ? (
                    <Tooltip title={t('done')}>
                      <Done sx={{ color: theme.palette.primary.main }} />
                    </Tooltip>
                  ) : (
                    <>
                      <LoadingSpinner />
                      <Box
                        sx={{
                          paddingLeft: '0.5rem',
                        }}
                      />
                      <Tooltip title={t('cancel-upload')}>
                        <IconButton
                          onClick={() => {
                            file.xhr.abort();
                          }}
                        >
                          <Close
                            sx={{
                              color: (theme) => theme.palette.text.secondary,
                              fontSize: 18,
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                </ListItem>
              </React.Fragment>
            ))}
          </List>
        </Box>
      )}
    </Box>
  );
};
