import { useAccount } from '@azure/msal-react';
import { GridState, useGridApiRef } from '@mui/x-data-grid-pro';
import { useLayoutEffect, useCallback } from 'react';

interface GridPreferences {
  [key: string]: GridState;
}

export function usePersistency(gridId: string | undefined, userId?: string) {
  const apiRef = useGridApiRef();
  const account = useAccount();

  const localUserId = account?.homeAccountId ?? userId;
  // generate a storage key based on the local user ID
  const storageKey = `grid-preferences-${localUserId}`;

  useLayoutEffect(() => {
    if (!localUserId || !gridId || !apiRef.current) {
      return;
    }
    const preferences = getPreferencesFromLocalStorage(storageKey);
    // if the user preferences have a state for this grid, restore it
    const savedGridState = preferences[gridId];
    // only update the state that is stored in the local storage
    const currentState = apiRef.current.exportState();
    // merge the current state with the saved state
    const newState = { ...currentState, ...savedGridState };
    apiRef.current.restoreState(newState);
  }, [apiRef, gridId, localUserId, storageKey]);

  const handleStateChange = useCallback(() => {
    // return early if there's no grid ID, local user ID or api ref
    if (!gridId || !apiRef.current || !localUserId) {
      return;
    }
    // get stored preferences
    const preferences = getPreferencesFromLocalStorage(storageKey);
    // get the current grid state from the api
    const state = apiRef.current.exportState();
    // save the state of the grid based on the user's local ID and the grid ID
    localStorage.setItem(
      storageKey,
      // use custom replacer to handle Date objects
      JSON.stringify({ ...preferences, [gridId]: state }, replacer)
    );
  }, [apiRef, gridId, localUserId, storageKey]);

  return {
    apiRef,
    handleStateChange,
  };
}

export type DateObject = { type: 'Date'; value: string };

export function reviver(_key: string, value?: DateObject | string | number | boolean) {
  // if the value is an object and has a type of 'Date', convert it back to a Date object
  if (typeof value === 'object' && value?.type === 'Date') {
    return new Date(value.value);
  }
  // otherwise, return the value as is
  return value;
}

// custom replacer to handle Date objects
// "this" represents the original value of the value that is being stringified,
// value is a pre-processed string which is returned by the replacer to not waste time on reprocessing the value
// that's why we need to use this[key] to get the original Date object instead of the pre-processed value
export function replacer(
  this: Record<string, unknown>,
  key: string,
  value: string
): DateObject | string {
  if (this[key] instanceof Date) {
    // store dates as { type: 'Date', value: 'some-iso-date-string' }
    return { type: 'Date', value: (this[key] as Date).toISOString() };
  }
  return value;
}

export function getPreferencesFromLocalStorage(storageKey: string) {
  const storedPreferences: string = localStorage.getItem(storageKey) ?? '{}';
  try {
    const preferences: GridPreferences = JSON.parse(storedPreferences, reviver);
    return preferences;
  } catch {
    console.warn('Could not parse preferences', storedPreferences);
    return {};
  }
}
