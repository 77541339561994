import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { useAccount } from '@azure/msal-react';
import { useTranslation } from 'react-i18next';
import UserMenu from './UserMenu/UserMenu';
import { useGetCurrentUser } from '~/queries-asset/user';
import { Roles } from '~/common/roles';

// const Search = styled('div')(({ theme }) => ({
//   position: 'relative',
//   borderRadius: theme.shape.borderRadius,
//   backgroundColor: alpha(theme.palette.common.white, 0.15),
//   '&:hover': {
//     backgroundColor: alpha(theme.palette.common.white, 0.25),
//   },
//   marginRight: theme.spacing(2),
//   marginLeft: 0,
//   width: '100%',
//   [theme.breakpoints.up('sm')]: {
//     marginLeft: theme.spacing(3),
//     width: 'auto',
//   },
// }));

// const SearchIconWrapper = styled('div')(({ theme }) => ({
//   padding: theme.spacing(0, 2),
//   height: '100%',
//   position: 'absolute',
//   pointerEvents: 'none',
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'center',
// }));

export function Header() {
  const { t } = useTranslation();
  const account = useAccount();
  const { data: user } = useGetCurrentUser();
  const isAdmin = user?.currentUser?.roles.includes(Roles.Delete);

  return (
    <>
      <AppBar position='absolute' color='inherit'>
        <Toolbar sx={{ zIndex: 1000 }}>
          <img src='/platform-vi-logo.png' height={32} width='auto' />
          {/* <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder={t('search')}
              inputProps={{ 'aria-label': 'search' }}
            />
          </Search> */}
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            <UserMenu
              user={account?.name ?? account?.username}
              role={isAdmin ? t('admin') : t('user')}
            />
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
}
