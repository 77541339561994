import React, { useMemo, useState } from 'react';
import FormSpy from '@data-driven-forms/react-form-renderer/form-spy';
import { AbandonChangesDialog, EnableBlocker, LeaveDialogLabels } from '~/components/Blocker';
import { FormSpyProps, FormSpyRenderProps } from 'react-final-form';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type SpyProps = FormSpyRenderProps<Record<string, any>, Partial<Record<string, any>>>;

export type SpyHandlerProps = SpyProps & {
  handleCancel(): void;
};

function useFormSpyWithBlocker(
  spyHandler: React.ReactNode | ((props: SpyHandlerProps) => React.ReactNode),
  onCancel: (props: SpyProps) => void,
  labels?: LeaveDialogLabels,
  disabled?: boolean
) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const spyFunc = useMemo(() => {
    const spyFunc: FormSpyProps['children'] = (props) => {
      if (typeof spyHandler === 'function') {
        const pristine =
          Object.keys(props.initialValues).length === Object.keys(props.values).length &&
          Object.keys(props.initialValues).every(
            (key) => props.values[key] === props.initialValues[key]
          );
        return (
          <>
            {spyHandler({
              ...props,
              // Add a handleCancel method to the spy props which will open a dialog
              // if the form isnt' pristine, and call onCancel otherwise
              handleCancel() {
                if (pristine || disabled) {
                  onCancel(props);
                } else {
                  setDialogOpen(true);
                }
              },
            })}
            <EnableBlocker enabled={!pristine && !disabled} />
            <AbandonChangesDialog
              labels={labels}
              open={dialogOpen}
              onClose={() => {
                setDialogOpen(false);
              }}
              onConfirm={async () => {
                setDialogOpen(false);
                onCancel(props);
              }}
            />
          </>
        );
      } else {
        return spyHandler;
      }
    };
    return spyFunc;
  }, [dialogOpen, labels, onCancel, spyHandler, disabled]);
  return { spyFunc };
}

/**
 * This wraps the `<FormSpy>` component to add a dialog which is shown when the
 * user cancels editing the form while it is not pristine (i.e. if there
 * are any changes to the initial data).
 *
 * The passed in `onCancel` method will wrapped and passed to the child function
 * in the `handleCancel` method. When `handleCancel` is called then if the
 * form is pristine, `onCancel` will be called as usual. However, if the form
 * is not pristine then the `AbandonChangesDialog` will be displayed using
 * the supplied `labels`. If the user confirms that they want to abandon their
 * changes then `onCancel` is called. Otherwise the dialog is closed and the
 * user can continue using the form.
 */
export const FormSpyWithBlocker: React.FC<{
  children: React.ReactNode | ((props: SpyHandlerProps) => React.ReactNode);
  onCancel: (props: SpyProps) => void;
  labels?: LeaveDialogLabels;
  disableBlocker?: boolean;
}> = ({ children, onCancel, labels, disableBlocker: disabled }) => {
  const { spyFunc } = useFormSpyWithBlocker(children, onCancel, labels, disabled);

  return <FormSpy>{spyFunc}</FormSpy>;
};
