import { GetViperAssetQuery } from '~/__generated-asset__/graphql';
import { componentTypes, dataTypes, Schema } from '@data-driven-forms/react-form-renderer';
import { useTranslation } from 'react-i18next';
import { formatDateTime } from '~/utils/date-utils';
import { useGetVlim } from '~/queries-data-retrieval/useGetVlim';

interface UseAutomaticPollingSchemaProps {
  viperAsset?: GetViperAssetQuery['viperAsset'];
}

export const useAutomaticPollingEditSchema = ({
  viperAsset,
}: UseAutomaticPollingSchemaProps): Schema => {
  const { t } = useTranslation('viper-asset-forms');

  const { data } = useGetVlim(viperAsset?.serialNumber);

  const remoteAccessOptions = [
    { value: true, label: t('remoteAccessOptions/yes') },
    { value: false, label: t('remoteAccessOptions/no') },
  ];

  return {
    fields: [
      {
        name: 'remoteAccess',
        label: t('remoteAccess'),
        component: componentTypes.SELECT,
        options: remoteAccessOptions,
        useStandardOptions: true,
        dataType: dataTypes.BOOLEAN,
        initialValue: data?.vlim?.remoteAccess || false,
        sx: { gridColumnEnd: 'span 2' },
      },
      {
        name: 'pollingIpAddress',
        placeholder: 'e.g 123.1.11.0',
        label: t('pollingIpAddress'),
        helperText: t('ip-address-helper'),
        component: componentTypes.TEXT_FIELD,
        initialValue: data?.vlim?.pollingIpAddress,
        sx: { gridColumnEnd: 'span 2' },
      },
      {
        name: 'lastPollingDate',
        label: t('lastPollingDate'),
        component: componentTypes.PLAIN_TEXT,
        initialValue: formatDateTime(data?.vlim?.pollingDates?.lastPollingDate),
      },
      {
        name: 'nextPollingDate',
        label: t('nextPollingDate'),
        component: componentTypes.PLAIN_TEXT,
        initialValue: formatDateTime(data?.vlim?.pollingDates?.nextPollingDate),
      },
    ],
  };
};
