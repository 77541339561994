import React from 'react';

import { Card, CardHeader, CardContent, CircularProgress, Box, SxProps } from '@mui/material';
interface DashboardCardProps {
  loading: boolean;
  title?: string;
  children?: React.ReactNode;
  sx?: SxProps;
}

export const DashboardCard: React.FC<DashboardCardProps> = ({
  loading,
  children,
  title,
  sx,
}) => {
  return (
    <Card
      sx={{
        p: 3,
        m: 0,
        gridColumnEnd: {
          sm: 'span 6',
          md: 'span 2',
        },
        placeSelf: 'stretch',
        ...sx,
      }}
    >
      {title && (
        <CardHeader
          title={title}
          sx={{ p: 0, m: 0 }}
          component='h2'
          data-testid='dashboard-card-header'
        />
      )}
      <CardContent sx={{ p: 0, m: 0 }}>
        {loading ? (
          <Box flex={1} display='flex' alignItems='center' justifyContent='center'>
            <CircularProgress />
          </Box>
        ) : (
          <>{children}</>
        )}
      </CardContent>
    </Card>
  );
};
