import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * A scalar that represents a date with no time component.
   *
   * It is serialised as yyyy-mm-dd
   */
  Date: string;
  /**
   * A scalar that represents a date with a time component.
   *
   * It is serialised as yyyy-mm-ddThh:mm:ssZ
   */
  DateTime: string;
  /** A scalar which represents java.lang.Long */
  Long: number;
};

export type Asset = {
  pollingIpAddress?: InputMaybe<Scalars['String']>;
  serialNumber: Scalars['String'];
};

/**
 * An Authenticated User.
 *
 * Any user that is logged in that executes certain operations will be recorded as an authenticated user.
 */
export type AuthenticatedUser = {
  __typename?: 'AuthenticatedUser';
  id: Scalars['ID'];
  /** The name as found in the user's access token. */
  name: Scalars['String'];
  /** The oid of the user. */
  oid: Scalars['String'];
};

export type JobResult = {
  __typename?: 'JobResult';
  started: Scalars['Boolean'];
};

export type Mutation = {
  __typename?: 'Mutation';
  importFile: JobResult;
  updateVlim?: Maybe<Vlim>;
};


export type MutationImportFileArgs = {
  viperAsset: Asset;
};


export type MutationUpdateVlimArgs = {
  serialNumber: Scalars['String'];
  vlimUpdateInput: VlimUpdateInput;
};

/**
 * An input type for passing non-null Dates.
 *
 * This is useful when you want to optionally include a non-null value in an input type.
 */
export type NonNullDate = {
  value: Scalars['Date'];
};

/**
 * An input type for passing non-null IDs.
 *
 * This is useful when you want to optionally include a non-null value in an input type.
 */
export type NonNullId = {
  value: Scalars['ID'];
};

/**
 * An input type for passing non-null Longs.
 *
 * This is useful when you want to optionally include a non-null value in an input type.
 */
export type NonNullLong = {
  value: Scalars['Long'];
};

/**
 * An input type for passing non-null strings.
 *
 * This is useful when you want to optionally include a non-null value in an input type.
 */
export type NonNullString = {
  value: Scalars['String'];
};

export type PollingDates = {
  __typename?: 'PollingDates';
  lastPollingDate?: Maybe<Scalars['DateTime']>;
  nextPollingDate?: Maybe<Scalars['DateTime']>;
};

export type Query = {
  __typename?: 'Query';
  getPollingDates: PollingDates;
  vlim?: Maybe<Vlim>;
  vlims: Array<Vlim>;
};


export type QueryGetPollingDatesArgs = {
  viperAsset: Asset;
};


export type QueryVlimArgs = {
  serialNumber: Scalars['String'];
};

/** Describes how to sort a list. */
export type Sort = {
  /** The order to sort in. */
  direction?: InputMaybe<SortDirection>;
  /**
   * The name of the field to sort by. This can be a dot separated path, such as
   * "field.customer.name"
   */
  field: Scalars['String'];
};

/** The sort direction for a sort operation. */
export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type Vlim = {
  __typename?: 'Vlim';
  pollingDates: PollingDates;
  pollingIpAddress?: Maybe<Scalars['String']>;
  remoteAccess: Scalars['Boolean'];
  serialNumber: Scalars['String'];
};

export type VlimUpdateInput = {
  pollingIpAddress?: InputMaybe<Scalars['String']>;
  remoteAccess?: InputMaybe<Scalars['Boolean']>;
};

export type AllVlimFieldsFragment = { __typename?: 'Vlim', serialNumber: string, remoteAccess: boolean, pollingIpAddress?: string | null, pollingDates: { __typename?: 'PollingDates', lastPollingDate?: string | null, nextPollingDate?: string | null } };

export type GetVlimsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetVlimsQuery = { __typename?: 'Query', vlims: Array<{ __typename?: 'Vlim', serialNumber: string, remoteAccess: boolean, pollingIpAddress?: string | null, pollingDates: { __typename?: 'PollingDates', lastPollingDate?: string | null, nextPollingDate?: string | null } }> };

export type GetVlimQueryVariables = Exact<{
  serialNumber: Scalars['String'];
}>;


export type GetVlimQuery = { __typename?: 'Query', vlim?: { __typename?: 'Vlim', serialNumber: string, remoteAccess: boolean, pollingIpAddress?: string | null, pollingDates: { __typename?: 'PollingDates', lastPollingDate?: string | null, nextPollingDate?: string | null } } | null };

export type UpdateVlimMutationVariables = Exact<{
  serialNumber: Scalars['String'];
  vlimInput: VlimUpdateInput;
}>;


export type UpdateVlimMutation = { __typename?: 'Mutation', updateVlim?: { __typename?: 'Vlim', serialNumber: string, remoteAccess: boolean, pollingIpAddress?: string | null, pollingDates: { __typename?: 'PollingDates', lastPollingDate?: string | null, nextPollingDate?: string | null } } | null };

export const AllVlimFieldsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"AllVlimFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Vlim"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"serialNumber"}},{"kind":"Field","name":{"kind":"Name","value":"remoteAccess"}},{"kind":"Field","name":{"kind":"Name","value":"pollingIpAddress"}},{"kind":"Field","name":{"kind":"Name","value":"pollingDates"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"lastPollingDate"}},{"kind":"Field","name":{"kind":"Name","value":"nextPollingDate"}}]}}]}}]} as unknown as DocumentNode<AllVlimFieldsFragment, unknown>;
export const GetVlimsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetVlims"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"vlims"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"AllVlimFields"}}]}}]}},...AllVlimFieldsFragmentDoc.definitions]} as unknown as DocumentNode<GetVlimsQuery, GetVlimsQueryVariables>;
export const GetVlimDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetVlim"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"serialNumber"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"vlim"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"serialNumber"},"value":{"kind":"Variable","name":{"kind":"Name","value":"serialNumber"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"AllVlimFields"}}]}}]}},...AllVlimFieldsFragmentDoc.definitions]} as unknown as DocumentNode<GetVlimQuery, GetVlimQueryVariables>;
export const UpdateVlimDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateVlim"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"serialNumber"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"vlimInput"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"VlimUpdateInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateVlim"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"serialNumber"},"value":{"kind":"Variable","name":{"kind":"Name","value":"serialNumber"}}},{"kind":"Argument","name":{"kind":"Name","value":"vlimUpdateInput"},"value":{"kind":"Variable","name":{"kind":"Name","value":"vlimInput"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"AllVlimFields"}}]}}]}},...AllVlimFieldsFragmentDoc.definitions]} as unknown as DocumentNode<UpdateVlimMutation, UpdateVlimMutationVariables>;