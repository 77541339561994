import React from 'react';
import { useTranslation } from 'react-i18next';

import { Table } from '~/components/Table/Table';
import { Box, Button, Typography } from '@mui/material';
import { useCustomerAssetMaintenanceLog } from './useCustomerAssetMaintenanceLog';
import { ACTION_EDIT } from '~/components/ActionMenu';
import { UpdateCustomerAssetMaintenanceLogDialog } from '../create-edit-dialogs/CustomerAssetMaintenanceLog/UpdateCustomerMaintenanceLogDialog';
import { CreateCustomerAssetMaintenanceLogDialog } from '../create-edit-dialogs/CustomerAssetMaintenanceLog/CreateCustomerAssetMaintenanceLogDialog';
import { useGetChannel } from '~/queries-asset/channel';

// t('customer-asset::actions')

const actionMenu = {
  label: 'actions',
  actionMenu: [ACTION_EDIT],
};

export const CustomerAssetMaintenanceLog: React.FC<{
  id: string;
}> = ({ id }) => {
  const { t } = useTranslation('customer-asset');

  const { data } = useGetChannel(id);
  const maintenanceLogs = data?.channel?.maintenanceLogs;

  const {
    tableData,
    tableDetails,
    handleMaintenanceLogActionClick,
    showMaintenanceLogDialog,
    setShowMaintenanceLogDialog,
    showCreateMaintenanceLogDialog,
    setShowCreateMaintenanceLogDialog,
  } = useCustomerAssetMaintenanceLog(maintenanceLogs);

  return (
    <>
      <Box display='flex' justifyContent='space-between' alignItems='center' sx={{ mb: 2 }}>
        <Typography variant='h3'>{t('maintenance-logs')}</Typography>
        <Button
          variant='contained'
          color='secondary'
          onClick={() => setShowCreateMaintenanceLogDialog(true)}
        >
          {t('create-maintenance-log')}
        </Button>
      </Box>
      {tableData.length > 0 && (
        <>
          <Table
            data={tableData}
            omittedKeys={['_id']}
            translationKey={'customer-asset'}
            nestedData={tableDetails}
            actionMenu={actionMenu}
            onActionClick={handleMaintenanceLogActionClick}
          />
          <UpdateCustomerAssetMaintenanceLogDialog
            open={!!showMaintenanceLogDialog}
            onClose={() => {
              setShowMaintenanceLogDialog(undefined);
            }}
            id={id}
            maintenanceLogs={maintenanceLogs}
            editId={showMaintenanceLogDialog}
          />
        </>
      )}
      <CreateCustomerAssetMaintenanceLogDialog
        open={showCreateMaintenanceLogDialog}
        onClose={() => {
          setShowCreateMaintenanceLogDialog(false);
        }}
        id={id}
      />
    </>
  );
};
