import React from 'react';
import { Box, BoxProps } from '@mui/material';

interface TabPanelProps extends BoxProps {
  children?: React.ReactNode;
  index: number | string;
  value: number | string;
}

export const TabPanel: React.FC<TabPanelProps> = ({ children, value, index, ...other }) => {
  return (
    <Box
      role='tabpanel'
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && children}
    </Box>
  );
};
