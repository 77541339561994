import { useTranslation } from 'react-i18next';
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid-pro';
import { Box, Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { ToolbarFilters } from '~/pages/LicenceInsights/types';
import { FilterCounts } from '~/pages/LicenceInsights/useFilterCounts';

interface FilterToolbarProps {
  activeFilters: ToolbarFilters;
  setActiveFilters: (filters: ToolbarFilters) => void;
  filterCounts: FilterCounts;
}
export const FilterToolbar = ({
  activeFilters,
  setActiveFilters,
  filterCounts,
}: FilterToolbarProps) => {
  const { t } = useTranslation('licence-insights');

  const { gainCount, attritionCount, flaggedForDecommissionCount } = filterCounts;

  const toggleGains = () => {
    setActiveFilters({ ...activeFilters, gains: !activeFilters.gains });
  };

  const toggleAttrition = () => {
    setActiveFilters({ ...activeFilters, attrition: !activeFilters.attrition });
  };

  const toggleFlaggedForDecommission = () => {
    setActiveFilters({
      ...activeFilters,
      flaggedForDecommission: !activeFilters.flaggedForDecommission,
    });
  };

  return (
    <>
      <GridToolbarContainer>
        <Box sx={{ display: 'flex', flexDirection: 'row', flexGrow: '1' }}>
          <Box sx={{ py: 0.5 }}>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
            <GridToolbarExport />
          </Box>
          <GridToolbarQuickFilter
            sx={{
              marginLeft: 'auto',
            }}
          />
        </Box>
      </GridToolbarContainer>
      <GridToolbarContainer>
        <Box sx={{ px: 3 }}>
          <FormGroup
            sx={{
              display: 'grid',
              gap: '0.5rem',
              gridTemplateColumns: {
                sm: 'repeat(1, 1fr)',
                md: 'repeat(2, 1fr)',
                lg: 'repeat(3, 1fr)',
              },
              gridTemplateRows: 'auto',
            }}
          >
            <FormControlLabel
              checked={activeFilters.gains ? activeFilters.gains : undefined}
              onChange={toggleGains}
              control={<Checkbox />}
              label={t('show-gains', { gainCount })}
            />
            <FormControlLabel
              checked={activeFilters.attrition ? activeFilters.attrition : undefined}
              onChange={toggleAttrition}
              control={<Checkbox />}
              label={t('show-attrition', { attritionCount })}
            />
            <FormControlLabel
              checked={
                activeFilters.flaggedForDecommission
                  ? activeFilters.flaggedForDecommission
                  : undefined
              }
              onChange={toggleFlaggedForDecommission}
              control={<Checkbox />}
              label={t('show-flagged-for-decommission', { flaggedForDecommissionCount })}
            />
          </FormGroup>
        </Box>
      </GridToolbarContainer>
    </>
  );
};
