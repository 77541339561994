import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { DashboardCard } from '../Dashboard/components/DashboardCard';
import {
  FormRenderer,
  FormTemplateRenderProps,
  componentTypes,
} from '@data-driven-forms/react-form-renderer';
import { componentMapper } from '~/components/Form/formFields/mappers/componentMapper';
import { StandaloneFormTemplate } from '~/components/Form/formFields/templates/FormTemplate';
import { extraValidatorTypes, validatorMapper } from '~/components/Form/validators';
import { DateRange, DateStringRange } from '~/common/types';
import { formatDateYYYYMMDD } from '~/components/DataGrid/filters/utils';

interface DateRangeCardProps {
  setValues: (values: DateStringRange) => void;
  defaultValues?: DateRange;
}

export const DateRangeCard: React.FC<DateRangeCardProps> = ({ setValues, defaultValues }) => {
  const { t } = useTranslation('date-range-card');

  const validator = useCallback(
    (values: DateRange) => {
      const errors: Record<string, string> = {};
      if (values.startDate && values.endDate && values.startDate > values.endDate) {
        errors.endDate = t('end-date-after-start-date');
        errors.startDate = t('end-date-before-start-date');
        return errors;
      }
    },
    [t]
  );

  return (
    <DashboardCard
      loading={false}
      sx={{
        gridColumnEnd: {
          sm: 'span 6',
          md: 'span 4',
        },
      }}
    >
      <FormRenderer
        componentMapper={componentMapper}
        FormTemplate={(props: FormTemplateRenderProps) => (
          <StandaloneFormTemplate setValues={setValues} {...props} />
        )}
        validatorMapper={validatorMapper}
        validate={validator}
        schema={{
          fields: [
            {
              component: componentTypes.DATE_PICKER,
              name: 'startDate',
              label: t('start-date'),
              validate: [{ type: extraValidatorTypes.VALID_DATE, message: t('invalid-date') }],
              initialValue: formatDateYYYYMMDD(defaultValues?.startDate),
              validateOnMount: true,
            },
            {
              component: componentTypes.DATE_PICKER,
              name: 'endDate',
              label: t('end-date'),
              validate: [{ type: extraValidatorTypes.VALID_DATE, message: t('invalid-date') }],
              initialValue: formatDateYYYYMMDD(defaultValues?.endDate),
              validateOnMount: true,
            },
          ],
        }}
        subscription={{ values: true }}
      />
    </DashboardCard>
  );
};
