import { MuiMarkdown, getOverrides } from 'mui-markdown';
import { useTranslation } from 'react-i18next';
import { Card, CardContent, Divider } from '@mui/material';

interface StaticContentProps {
  content: string;
  translationParams?: {
    [key: string]: string;
  };
}

export const StaticContent = ({ content, translationParams }: StaticContentProps) => {
  const { t } = useTranslation('static');

  return (
    <Card>
      <CardContent sx={{ mt: 4, mx: 8, mb: 8 }}>
        <MuiMarkdown
          options={{
            overrides: {
              ...getOverrides({}), // This will keep the other default overrides.
              h1: {
                component: 'h1',
                props: {
                  style: { fontSize: 32, fontWeight: 700, lineHeight: 1.2 },
                },
              },
              h2: {
                component: 'h2',
                props: {
                  style: { fontSize: 20, fontWeight: 700, lineHeight: 1.2 },
                },
              },
              h3: {
                component: 'h3',
                props: {
                  style: { fontSize: 16, fontWeight: 700, lineHeight: 1.2 },
                },
              },
            },
          }}
        >
          {translationParams ? t(content, translationParams) : t(content)}
        </MuiMarkdown>
        <Divider sx={{ mt: 4 }} />
      </CardContent>
    </Card>
  );
};
