import { useCallback } from 'react';
import { Tabs, Tab } from '@mui/material';
import { TabPanel } from '~/components/TabPanel';
import { useTranslation } from 'react-i18next';
import { VLifeReportForms } from './panes/VLifeReportForms';
import { PendingVLifeReports } from './panes/PendingVLifeReports';
import { useLocation, useNavigate } from 'react-router-dom';
import { Path } from '~/routes/paths';
import { formDefaults } from './panes/formDefaults';

export const VLifeReports: React.FC = () => {
  const { t } = useTranslation('v-life-reports');
  const navigate = useNavigate();

  /**
   * Instead of storing the tab number in state, use the URL (location.pathname)
   * to indicate which tab we're on, and use navigate to change tabs.
   */
  const location = useLocation();
  const handleTabChange = useCallback(
    (_e: React.SyntheticEvent, newValue: string) => navigate(newValue),
    [navigate]
  );

  return (
    <>
      <Tabs
        value={location.pathname}
        onChange={handleTabChange}
        aria-label={`tab-${location.pathname}`}
        sx={{ mb: 4 }}
      >
        {/** The pending reports tab is the first tab, so we don't use a sub-path for it */}
        <Tab label={t('pending')} value={Path.V_LIFE_REPORTS} />
        <Tab label={t('review')} value={formDefaults.review.path} />
        <Tab label={t('approve')} value={formDefaults.approve.path} />
        <Tab label={t('publish')} value={formDefaults.publish.path} />
      </Tabs>
      <TabPanel index={Path.V_LIFE_REPORTS} value={location.pathname}>
        <PendingVLifeReports />
      </TabPanel>
      <TabPanel index={formDefaults.review.path} value={location.pathname}>
        <VLifeReportForms statePersistencyId='review-v-life-reports' />
      </TabPanel>
      <TabPanel index={formDefaults.approve.path} value={location.pathname}>
        <VLifeReportForms statePersistencyId='approve-v-life-reports' />
      </TabPanel>
      <TabPanel index={formDefaults.publish.path} value={location.pathname}>
        <VLifeReportForms statePersistencyId='publish-v-life-reports' />
      </TabPanel>
    </>
  );
};
