import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { VLifeReportLogMessage } from '~/__generated-asset__/graphql';
import Box from '@mui/material/Box';
import { DataGrid } from '~/components/DataGrid';
import { useSelectMessagesColumns } from '~/pages/VLifeReports/useSelectMessagesColumns';
import Grid from '@mui/material/Grid';
import { Dialog } from '~/components/Dialog';
import { GridRowId, GridRowParams } from '@mui/x-data-grid-pro';
import { ArrowForward } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { AddMessageToolbar } from '~/pages/VLifeReports/AddMessageToolbar';

export interface SelectEventProps {
  open: boolean;
  selectedLogs: VLifeReportLogMessage[];
  allLogs: VLifeReportLogMessage[];
  onClose: () => void;
  onConfirm: (selectedEvents: VLifeReportLogMessage[]) => void;
}

export const getEventIdentifier = (event: VLifeReportLogMessage) => {
  return `${event.date}_${event.message}`;
};

export const SelectEvents = ({
  open,
  allLogs,
  selectedLogs,
  onClose,
  onConfirm,
}: SelectEventProps) => {
  const { t } = useTranslation('select-messages');
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowId[]>([]);
  const [selectedEvents, setSelectedEvents] = useState<VLifeReportLogMessage[]>(selectedLogs);

  const rowIdsToMove = useMemo(
    () =>
      rowSelectionModel.filter((rowId) => !selectedEvents.find((event) => event.id === rowId)),
    [rowSelectionModel, selectedEvents]
  );

  // Set the initial selected row IDs, by matching the dates and messages to row IDs
  useEffect(() => {
    setRowSelectionModel(
      allLogs
        .filter((event) =>
          selectedLogs.find(
            (selectedLog) => getEventIdentifier(event) === getEventIdentifier(selectedLog)
          )
        )
        .map((event) => event.id)
    );
  }, [allLogs, selectedLogs]);

  const updateSelectedEvents = (events: VLifeReportLogMessage[]) => {
    const updatedEvents = [...events];
    updatedEvents.sort((a, b) => {
      return new Date(a.date).getTime() - new Date(b.date).getTime();
    });
    setSelectedEvents(updatedEvents);
  };

  // Delete rows from the right-hand table, and unselect them from the left-hand table
  const handleRowDeletion = (row: VLifeReportLogMessage) => {
    updateSelectedEvents(selectedEvents.filter((event) => event.id !== row.id));
    // Find row ID from left hand column and un-select
    const allLogsRow = allLogs.find(
      (event) => getEventIdentifier(event) === getEventIdentifier(row)
    );
    if (allLogsRow) {
      setRowSelectionModel(
        rowSelectionModel.filter((selectedRowId) => selectedRowId !== allLogsRow.id)
      );
    }
  };

  const { columnsWithSelect, columnsWithDelete } = useSelectMessagesColumns({
    rowDeletionCallback: handleRowDeletion,
  });

  // Add custom messages to the right-hand table
  const handleAddMessage = (message: VLifeReportLogMessage) => {
    updateSelectedEvents([...selectedEvents, message]);
  };

  // Manage the selection model for the left-hand table, to make moving and querying easier
  const handleRowSelection = (selectionModel: GridRowId[]) => {
    setRowSelectionModel(selectionModel);
  };

  const handleSelectEvents = () => {
    // Get rows by ID and add them to the selected rows
    const newlySelectedRows = allLogs.filter((event) => {
      return (
        rowSelectionModel.includes(event.id) &&
        !selectedEvents.find(
          (selectedEvent) => getEventIdentifier(selectedEvent) === getEventIdentifier(event)
        )
      );
    });
    updateSelectedEvents([...selectedEvents, ...newlySelectedRows]);
  };

  const handleClose = () => {
    onClose();
  };

  const handleConfirm = () => {
    onConfirm(selectedEvents);
  };

  const isRowSelectable = ({ row }: GridRowParams<VLifeReportLogMessage>) => {
    return !selectedEvents.find(
      (selectedEvent) => getEventIdentifier(selectedEvent) === getEventIdentifier(row)
    );
  };

  return (
    <Dialog
      fullWidth
      maxWidth='xl'
      title={t('select-events')}
      confirmText={t('confirm-text')}
      onClose={handleClose}
      onConfirm={handleConfirm}
      open={open}
      content={
        <Box overflow='hidden'>
          <Grid container columns={12} p='1px'>
            <Grid xs={5.75} item pr={1}>
              <Box mb={2}>{t('events-to-select-description')}</Box>
              <Box>
                <DataGrid
                  sx={{ height: 500 }}
                  columnVisibilityModel={{
                    id: false,
                  }}
                  checkboxSelection={true}
                  onRowSelectionModelChange={handleRowSelection}
                  rowSelectionModel={rowSelectionModel}
                  isRowSelectable={isRowSelectable}
                  disableDensitySelector={true}
                  disableColumnSelector={true}
                  columns={columnsWithSelect}
                  rows={allLogs}
                  pagination={false}
                />
              </Box>
            </Grid>
            <Grid
              xs={0.5}
              item
              width={60}
              display='flex'
              flexDirection='column'
              justifyContent='center'
            >
              <IconButton
                data-testId='move-selected'
                sx={{ alignSelf: 'center' }}
                onClick={handleSelectEvents}
                disabled={rowIdsToMove.length === 0}
                size='small'
              >
                <ArrowForward />
              </IconButton>
            </Grid>
            <Grid xs={5.75} item pl={1}>
              <Box mb={2}>{t('events-selected-description')}</Box>
              <DataGrid
                columns={columnsWithDelete}
                rows={selectedEvents}
                slots={{
                  toolbar: AddMessageToolbar,
                }}
                slotProps={{
                  toolbar: {
                    onAddMessage: handleAddMessage,
                  },
                }}
                sx={{ height: 500 }}
                columnVisibilityModel={{
                  id: false,
                }}
                disableDensitySelector={true}
                disableColumnSelector={true}
                disableColumnFilter={true}
                pagination={false}
              />
            </Grid>
          </Grid>
        </Box>
      }
    />
  );
};
