import React, { ChangeEvent } from 'react';
import MuiTextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import { InputProps } from '@mui/material';

export interface SearchInputProps {
  label?: string;
  placeholder?: string;
  onChange: (term?: string) => void;
  sx?: InputProps['sx'];
}

export const SearchInput: React.FC<SearchInputProps> = ({
  onChange,
  placeholder = 'Search…',
  label,
  ...rest
}) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  return (
    <MuiTextField
      size='small'
      label={label}
      placeholder={placeholder}
      onChange={handleChange}
      InputProps={{
        startAdornment: <SearchIcon />,
      }}
      inputProps={{
        role: 'searchbox',
      }}
      {...rest}
    />
  );
};
