import Box from '@mui/material/Box';
import { List } from '@mui/material';
import { MainMenu } from './MainMenu';
import { UploadIndicator } from '../upload/UploadIndicator';

export const SidebarNav: React.FC = () => {
  return (
    <Box
      sx={{
        backgroundColor: (theme) => theme.palette.grey[100],
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
        width: '280px',
      }}
    >
      <Box
        component='main'
        sx={{
          backgroundColor: (theme) => theme.palette.primary.main,
          flexGrow: 1,
          height: '100vh',
          position: 'relative',
        }}
      >
        <List component='nav' sx={{ flex: '0 0 auto', p: 1 }}>
          <MainMenu />
        </List>
        <UploadIndicator />
      </Box>
    </Box>
  );
};
