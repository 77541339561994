import { GridFilterInputValueProps } from '@mui/x-data-grid-pro';
import { TextFieldProps } from '@mui/material/TextField';
import { Box, FormControlLabel, SelectChangeEvent, Switch } from '@mui/material';
import { CustomInput } from '~/components/Table/components/CustomInput';
import { useFilterDebounceAndApply } from '~/components/DataGrid/filters/useFilterDebounceAndApply';
import { formatDateYYYYMMDD } from '~/components/DataGrid/filters/utils';
import { useTranslation } from 'react-i18next';
import { NegatableVLifeLicenceChangeRangeFilters } from '~/components/DataGrid/filters/custom/v-life-licences/types';
import { VLifeStatusSelect } from '~/components/DataGrid/filters/custom/inputs/VLifeStatusSelect';
import { VLifeStatus } from '~/__generated-asset__/graphql';

const defaultValues: NegatableVLifeLicenceChangeRangeFilters = {
  isNegated: false,
  fromStatuses: [],
  toStatuses: [],
  startDate: undefined,
  endDate: undefined,
};

export const VLifeLicenceChangeInputFilter = ({
  item,
  applyValue,
}: GridFilterInputValueProps) => {
  const { t } = useTranslation('filter-inputs');
  const { values, updateFilterValue } =
    useFilterDebounceAndApply<NegatableVLifeLicenceChangeRangeFilters>(
      item,
      applyValue,
      defaultValues
    );

  const { fromStatuses, toStatuses, startDate, endDate } = values;

  const handleStartDateFilterChange: TextFieldProps['onChange'] = (event) => {
    const startDate = new Date(event.target.value);
    updateFilterValue({ startDate });
  };

  const handleEndDateFilterChange: TextFieldProps['onChange'] = (event) => {
    const endDate = new Date(event.target.value);
    updateFilterValue({ endDate });
  };

  const handleToStatusesFilterChange = (event: SelectChangeEvent<VLifeStatus[]>) => {
    const { value } = event.target;

    let toStatuses: VLifeStatus[];
    if (!Array.isArray(value)) {
      toStatuses = [value as VLifeStatus];
    } else {
      toStatuses = value;
    }

    updateFilterValue({ toStatuses });
  };

  const handleFromStatusesFilterChange = (event: SelectChangeEvent<VLifeStatus[]>) => {
    const { value } = event.target;

    console.log(typeof value, value);

    let fromStatuses: VLifeStatus[];
    if (!Array.isArray(value)) {
      fromStatuses = [value as VLifeStatus];
    } else {
      fromStatuses = value;
    }

    updateFilterValue({ fromStatuses });
  };

  const handleNegationFilterChange = (): void => {
    updateFilterValue({ isNegated: !values.isNegated });
  };

  return (
    <Box>
      <VLifeStatusSelect
        name='from-statuses'
        label='from-statuses'
        value={fromStatuses}
        multiple
        placeholder={t('from')}
        onChange={handleFromStatusesFilterChange}
      />
      <VLifeStatusSelect
        name='to-statuses'
        label='to-statuses'
        value={toStatuses}
        multiple
        placeholder={t('to')}
        onChange={handleToStatusesFilterChange}
      />
      <CustomInput
        name='start-date'
        placeholder={t('start-date')}
        value={formatDateYYYYMMDD(startDate)}
        onChange={handleStartDateFilterChange}
        type='date'
        style={{ marginRight: '8px', flex: '0 0.5 50%' }}
      />
      <CustomInput
        name='end-date'
        placeholder={t('end-date')}
        value={formatDateYYYYMMDD(endDate)}
        onChange={handleEndDateFilterChange}
        type='date'
        style={{ flex: '0 0.5 50%' }}
      />
      <FormControlLabel
        sx={{ ml: 2 }}
        label={t('negate')}
        control={
          <Switch
            checked={values.isNegated}
            onChange={handleNegationFilterChange}
            size='small'
          />
        }
      />
    </Box>
  );
};
