import { SelectChangeEvent, styled, Select, MenuItem } from '@mui/material';

export const StyledSelect = styled(Select)(({ theme }) => ({
  '& .MuiSelect-select': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.grey[500]}`,
    fontSize: 16,
    padding: '4px 24px 4px 8px',
    '&:focus': {
      borderRadius: 4,
      borderColor: theme.palette.grey[500],
      boxShadow: `0 0 0 1px ${theme.palette.grey[500]}`,
    },
    flex: '1 1 100%',
    width: '191px',
  },
}));

type SelectOptionValue = string | undefined;

interface SelectOption {
  label: string;
  value: SelectOptionValue;
}

interface FilterSelectProps {
  value: SelectOptionValue;
  onChange: (event: SelectChangeEvent<unknown>) => void;
  options: SelectOption[];
  placeholder?: string;
}

export const FilterSelect = ({ value, onChange, options, placeholder }: FilterSelectProps) => {
  return (
    <StyledSelect value={value} onChange={onChange} placeholder={placeholder}>
      <MenuItem key='undefined' value=''>
        &nbsp;
      </MenuItem>
      {options.map((option: SelectOption) => (
        <MenuItem key={option.label} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </StyledSelect>
  );
};
