import { GridFilterInputValueProps } from '@mui/x-data-grid-pro';
import { Box, FormControlLabel, SelectChangeEvent, Switch } from '@mui/material';
import { NegatableVLifeStatusListFilters } from '~/components/DataGrid/filters/types';
import { useFilterDebounceAndApply } from '~/components/DataGrid/filters/useFilterDebounceAndApply';
import { useTranslation } from 'react-i18next';
import { VLifeStatusSelect } from '../../inputs/VLifeStatusSelect';
import { VLifeStatus } from '~/__generated-asset__/graphql';

const defaultValues: NegatableVLifeStatusListFilters = {
  isNegated: false,
  comparatorValues: [],
};

export const VLifeStatusListInputFilter = ({
  item,
  applyValue,
}: GridFilterInputValueProps) => {
  const { t } = useTranslation();
  const { values, updateFilterValue } =
    useFilterDebounceAndApply<NegatableVLifeStatusListFilters>(
      item,
      applyValue,
      defaultValues
    );

  const handleStatusFilterChange = (event: SelectChangeEvent<VLifeStatus[]>) => {
    const { value } = event.target;
    const { comparatorValues } = values;

    console.log('comparatorValues', comparatorValues, value);

    const selectedStatus = value as VLifeStatus;

    if (comparatorValues && !comparatorValues.includes(selectedStatus)) {
      updateFilterValue({ comparatorValues: [...comparatorValues, selectedStatus] });
    } else if (comparatorValues && comparatorValues.includes(selectedStatus)) {
      updateFilterValue({
        comparatorValues: comparatorValues.filter((status) => status !== selectedStatus),
      });
    }
  };

  const handleNegationFilterChange = (): void => {
    updateFilterValue({ isNegated: !values.isNegated });
  };

  return (
    <Box>
      <VLifeStatusSelect
        name='statuses'
        placeholder={t('filter-inputs::statuses')}
        value={values.comparatorValues}
        onChange={handleStatusFilterChange}
        type='number'
      />
      <FormControlLabel
        sx={{ ml: 2 }}
        label={t('filter-inputs::negate')}
        control={
          <Switch
            checked={values.isNegated}
            onChange={handleNegationFilterChange}
            size='small'
          />
        }
      />
    </Box>
  );
};
