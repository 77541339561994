import React from 'react';
import HomeIcon from '@mui/icons-material/Home';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { matchRoutes, NavLink, useLocation, useParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { NavigationItem } from '~/routes/mainNavigation';

export const BreadcrumbsWithLogout: React.FC<{
  routes: NavigationItem[];
}> = ({ routes }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const location = useLocation();
  const params = useParams();

  const path = location.pathname !== '/' ? location.pathname.split('/') : [];
  const lastItem = path.pop();
  const currentPath = matchRoutes(routes, location)?.find(
    (match) => match.pathname === location.pathname
  );
  const routeParts = currentPath?.route?.path?.split('/');

  /**
   * Gets the label for an item with `key` at position `index` in the path.
   *
   * If the item at `index` is a parameter (i.e. it starts with `:`), the
   * parameter's value is returned.
   *
   * Otherwise the translation of `nav/${key}` is returned.
   */
  function getLabel(index: number, key: string) {
    const routePart = routeParts?.[index || 0];
    if (!routePart) {
      return '';
    }
    return routePart.startsWith(':') ? params[routePart.substring(1)] : t(`nav/${key}`);
  }

  let subPath = '';
  return path.length !== 0 ? (
    <Breadcrumbs separator='›' aria-label='breadcrumbs' sx={{ py: 3 }}>
      {path.map((item, i) => {
        if (item !== '') {
          subPath += `/${item}`;
          return (
            <NavLink
              to={subPath}
              style={{ textDecoration: 'none', color: theme.palette.primary.main }}
              key={item}
            >
              {getLabel(i, item)}
            </NavLink>
          );
        } else
          return (
            <NavLink
              to='/'
              style={{ textDecoration: 'none', color: theme.palette.primary.main }}
              key={item}
            >
              <HomeIcon />
            </NavLink>
          );
      })}
      {/** If we're on a tab, don't show the last part of the path as that is
       * the tab name, which is displayed in the tab bar instead */}
      {currentPath?.route?.tab ? (
        ''
      ) : (
        <Typography fontSize='inherit'>
          {getLabel((routeParts?.length || 1) - 1, lastItem || '')}
        </Typography>
      )}
    </Breadcrumbs>
  ) : null;
};
