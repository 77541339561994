import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Chip } from '@mui/material';

interface ColoredStatusIconProps {
  color: string;
  size?: 'small' | 'medium';
  onClick?: () => void;
  label?: string;
}

export const ColoredStatusIcon = ({ color, size, onClick, label }: ColoredStatusIconProps) => {
  return (
    <Chip
      icon={<FiberManualRecordIcon data-testid='indicator' style={{ color }} />}
      size={size}
      sx={{ backgroundColor: (theme) => theme.palette.grey['300'], pl: '8px', width: '18px' }}
      label={label}
      onClick={onClick}
    />
  );
};
