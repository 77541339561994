import React from 'react';
import { ButtonTypeMap } from '@mui/material';

import { ActionContext } from './ActionProvider';

export type Action = {
  label: string;
  onClick?: () => void;
  variant?: ButtonTypeMap['props']['variant'];
  color?: ButtonTypeMap['props']['color'];
};

export const Actions: React.FC<{ actions: Action[] }> = ({ actions }) => {
  const actionContext = React.useContext(ActionContext);
  React.useEffect(() => {
    actionContext.setActions(actions);
    return () => {
      actionContext.setActions([]);
    };
  }, [actionContext, actions]);

  return <></>;
};
