import React from 'react';
import { Box, ButtonGroup, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Card } from '~/components/Card';
import { useCustomerAssetGraph } from './useCustomerAssetGraph';
import { SelectButton } from '~/components/SelectButton';
import { ChartWithLoadingAndErrorMessages } from '~/pages/Graphs/ChartWithLoadingAndErrorMessages';

export const CustomerAssetGraph: React.FC<{ channelId?: string }> = ({ channelId }) => {
  const { t } = useTranslation('customer-asset-graph');
  const {
    yAxisScale,
    setYAxisScale,
    handlePeriodChange,
    period,
    loading,
    csv,
    error,
    maxIrCapError,
  } = useCustomerAssetGraph(channelId);

  return (
    <Card>
      <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
        <Box display='flex' flexDirection='column' gap={2}>
          <Typography variant='h3'>{t('customer-asset-graph')}</Typography>
          <Typography variant='body2'>{t('subtitle')}</Typography>
        </Box>
        <Box display='flex' flexDirection='row' gap={2} alignItems='flex-end'>
          <ButtonGroup size='small' disableRipple disableElevation aria-label='y axis scale'>
            <SelectButton
              label={t('logarithmic')}
              selected={yAxisScale === 'logarithmic'}
              onClick={() => setYAxisScale('logarithmic')}
            />
            <SelectButton
              label={t('linear')}
              selected={yAxisScale === 'linear'}
              onClick={() => setYAxisScale('linear')}
            />
          </ButtonGroup>
          <ButtonGroup size='small' disableRipple disableElevation aria-label='period'>
            <SelectButton
              label={t('all-time')}
              selected={period === 'allTime'}
              onClick={() => handlePeriodChange('allTime')}
            />
            <SelectButton
              label={t('three-months')}
              selected={period === 'threeMonths'}
              onClick={() => handlePeriodChange('threeMonths')}
            />
            <SelectButton
              label={t('one-month')}
              selected={period === 'oneMonth'}
              onClick={() => handlePeriodChange('oneMonth')}
            />
            <SelectButton
              label={t('two-weeks')}
              selected={period === 'twoWeeks'}
              onClick={() => handlePeriodChange('twoWeeks')}
            />
          </ButtonGroup>
        </Box>
      </Box>
      <ChartWithLoadingAndErrorMessages
        loading={loading}
        maxIrCapError={maxIrCapError}
        error={!!error}
        csv={csv}
        yAxisScale={yAxisScale}
        graphOptions={{
          drawPoints: false,
          fillGraph: true,
          strokeWidth: 2,
          connectSeparatedPoints: true,
        }}
      />
    </Card>
  );
};
