import { CreateWizard } from '~/components/Form/Wizard/CreateWizard';
import { LoadingSkeleton } from '~/components/LoadingSkeleton';
import { SaveError } from '~/components/Form/SaveError';
import { useCompany } from './useCompany';
import { useTranslation } from 'react-i18next';

export const CreateCompany: React.FC = () => {
  const { t } = useTranslation('company');

  const {
    optionsLoading,
    saving,
    errorState,
    createCompanySchema,
    handleSubmit,
    handleCancel,
  } = useCompany();

  if (optionsLoading) {
    return <LoadingSkeleton />;
  }

  return (
    <>
      <SaveError errorState={errorState}>{t('error-creating-company')}</SaveError>

      <CreateWizard
        schema={createCompanySchema}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        saving={saving}
      />
    </>
  );
};
