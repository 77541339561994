import React from 'react';
import { Button, Card, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const NoLifecyclePane: React.FC<{ onClick?: () => void; buttonDisabled?: boolean }> = ({
  onClick,
  buttonDisabled,
}) => {
  const { t } = useTranslation('viper-asset');
  return (
    <Card sx={{ padding: '32px' }} data-testid='no-lifecycle-pane'>
      <Typography variant='h3' component='h3'>
        {t('asset-installation')}
      </Typography>
      <Typography sx={{ paddingTop: 2, paddingBottom: 4 }}>{t('get-started')}</Typography>
      <Button
        variant='contained'
        color='secondary'
        onClick={onClick}
        disabled={buttonDisabled}
      >
        {t('install-asset')}
      </Button>
    </Card>
  );
};
