import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Actions } from '~/components/Actions/Actions';
import { FormDialog } from '~/components/Form/FormDialog';
import { SaveError } from '~/components/Form/SaveError';
import { LoadingSkeleton } from '~/components/LoadingSkeleton';
import { Table } from '~/components/Table/Table';
import { Toast } from '~/components/Toast';
import { Path } from '~/routes/paths';
import { useCompanies } from './useCompanies';

// t('companies::name')
// t('companies::address')
// t('companies::headquarters')
// t('companies::country')
// t('companies::continent')
// t('companies::channelCount')
// t('companies::actions')

export const Companies: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('companies');
  const {
    data,
    loading,
    saving,
    error,
    optionsError,
    companyError,
    closeError,
    setCloseError,
    handleActionClick,
    actionMenu,
    editing,
    onClose,
    formFields,
    onSave,
    updateCompanyErrorState,
    companyLoading,
  } = useCompanies();

  if (loading || saving) {
    return <LoadingSkeleton />;
  }

  return (
    <>
      <Actions
        actions={[
          {
            label: t('create-company'),
            onClick: () => {
              navigate(Path.CREATE_COMPANY);
            },
          },
        ]}
      />
      <Toast
        severity='error'
        open={Boolean(error) && !closeError && Boolean(optionsError) && Boolean(companyError)}
        onClose={() => setCloseError(true)}
      >
        {t('unable-to-load')}
      </Toast>

      <SaveError errorState={updateCompanyErrorState}>{t(`error-updating`)}</SaveError>

      <Table
        data={data}
        searchable
        omittedKeys={['_id']}
        translationKey={'companies'}
        actionMenu={actionMenu}
        onActionClick={handleActionClick}
        defaultOrderBy='name'
      />

      <FormDialog
        fields={formFields}
        onCancel={onClose}
        onSave={onSave}
        title={t('edit-company')}
        open={!!editing && !companyLoading}
        disabled={saving || companyLoading}
      />
    </>
  );
};
