import Paper from '@mui/material/Paper';
import {
  DataGridPro,
  DataGridProProps,
  GridCell,
  GridCellProps,
  GridColumnVisibilityModel,
  GridRow,
  GridRowProps,
  LicenseInfo,
} from '@mui/x-data-grid-pro';
import React from 'react';
import { CustomSelect } from '../Table/components/CustomSelect';
import { CustomInput } from '../Table/components/CustomInput';
import { GridPagination } from '../Table/components/GridPagination';
import { MenuItem } from '@mui/material';
import { PathValue, buildPath } from '~/routes/paths';
import { Link } from 'react-router-dom';
import { CustomToolbar } from '../Table/components/CustomToolbar';
import { usePersistency } from './hooks/usePersistency';
import { env } from '~/config/environmentVariables';

LicenseInfo.setLicenseKey(env.REACT_APP_MUI_X_LICENCE_KEY);

interface CustomDataGridProps extends DataGridProProps {
  initialColumnVisibilityModel?: GridColumnVisibilityModel;
  rowClickUrlBase?: PathValue;
  rowClickParamName?: string;
  statePersistencyId?: string;
}

const DataGrid = ({
  initialColumnVisibilityModel,
  rowClickUrlBase,
  rowClickParamName,
  statePersistencyId,
  ...props
}: CustomDataGridProps) => {
  const { apiRef, handleStateChange } = usePersistency(statePersistencyId);
  const CustomGridRow = React.useCallback(
    (props: GridRowProps) => {
      if (rowClickUrlBase && rowClickParamName) {
        return (
          <Link
            style={{ textDecoration: 'none' }}
            to={buildPath(rowClickUrlBase, { [rowClickParamName]: `${props.rowId}` })}
          >
            <GridRow {...props} />
          </Link>
        );
      }
      return <GridRow {...props} />;
    },
    [rowClickParamName, rowClickUrlBase]
  );

  return (
    <Paper>
      <DataGridPro
        apiRef={apiRef}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
          columns: {
            columnVisibilityModel: initialColumnVisibilityModel,
          },
        }}
        disableRowSelectionOnClick
        pagination
        slots={{
          pagination: GridPagination,
          toolbar: CustomToolbar,
          baseTextField: CustomInput,
          baseSelect: CustomSelect,
          baseSelectOption: MenuItem,
          cell: CustomGridCell,
          row: CustomGridRow,
        }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
          columnsPanel: {
            autoFocusSearchField: false,
          },
          filterPanel: {
            sx: {
              '&.MuiDataGrid-filterForm': {
                alignItems: 'center',
              },
              '& .MuiFormControl-root.MuiDataGrid-filterFormValueInput': {
                flex: '1 1 50%',
                width: 'unset',
                '& .MuiBox-root': {
                  display: 'flex',
                  justifyContent: 'space-between',
                  maxWidth: '450px',
                  '& .MuiInput-root': {
                    flex: '1 1 100%',
                    '& .MuiInputBase-root': {
                      width: '100%',
                      '& input': {
                        padding: '4px 8px 4px 8px',
                      },
                    },
                  },
                },
              },
            },
          },
        }}
        {...props}
        columns={props.columns}
        onStateChange={handleStateChange}
      />
    </Paper>
  );
};

export { DataGrid };

function CustomGridCell(props: GridCellProps) {
  if (props.value === undefined || props.value === null || props.value === '') {
    return <GridCell {...props}>–</GridCell>;
  }
  return <GridCell {...props} />;
}
