import { useRef } from 'react';
import { BoxPlotDatum, ResponsiveBoxPlot } from '@nivo/boxplot';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Theme,
} from '@mui/material';
import { attritionPadding, attritionScheme } from './defaults';
import { BoxPlotTooltip, CustomAttritionLabels, CustomVerticalGrid } from './ChartLabels';
import { useTranslation } from 'react-i18next';
import { useExportDomElementToPng } from '~/common/useExportDomToPng';

export interface GainsAttritionChartProps {
  data: BoxPlotDatum[];
  loading: boolean;
  title: string;
  xAxis: {
    minimum: number;
    maximum: number;
  };
}

const previousDataStyles = {
  '& svg > g > g[data-key="boxplot.0.0"]': {
    '& > rect': {
      transform: 'translate(0px,0px)',
      fill: ({ palette }: Theme) => palette.divider,
    },
    '& > line': {
      display: 'none',
    },
    '& > line:nth-of-child(2)': {
      display: 'block',
      stroke: ({ palette }: Theme) => palette.divider,
    },
  },
};

export const GainsAttritionChart = ({
  data,
  loading,
  title,
  xAxis,
}: GainsAttritionChartProps) => {
  const { t } = useTranslation('gains-attrition-chart');
  const exportDomContainer = useRef<HTMLDivElement>(null);

  const { exportEnabled, handleExport } = useExportDomElementToPng(
    exportDomContainer,
    'financial-year-gains-attrition.png'
  );

  return (
    <Card
      sx={{
        px: 2,
        py: 1.5,
        gridColumnEnd: 'span 6',
      }}
    >
      <CardHeader
        title={title}
        sx={{ pb: 0, m: 0 }}
        component='h2'
        subheaderTypographyProps={{ fontSize: 16, color: 'text.secondary' }}
      />
      <CardContent
        ref={exportDomContainer}
        sx={{
          mb: -3,
          height: 400,
          px: 5,
          pb: 5,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          ...previousDataStyles,
        }}
      >
        {loading ? (
          <CircularProgress />
        ) : (
          <ResponsiveBoxPlot
            data={data}
            margin={{ top: 20, right: 0, bottom: 40, left: 30 }}
            subGroupBy='subgroup'
            subGroups={['Gain', 'Attrition']}
            quantiles={[1, 0, 1, 1, 1]}
            padding={0.25}
            innerPadding={attritionPadding}
            enableGridX={true}
            medianWidth={1}
            medianColor={{ from: 'color', modifiers: [] }}
            borderWidth={0}
            borderColor='black'
            minValue={xAxis.minimum}
            maxValue={xAxis.maximum}
            // @ts-expect-error - Nivo types are not up to date (props should be ComputedBoxPlotSummary)
            tooltip={BoxPlotTooltip}
            axisBottom={{
              tickRotation: 45,
              tickSize: 0,
              tickPadding: 10,
              tickValues: 5,
            }}
            colors={attritionScheme}
            gridXValues={['Gain', 'Attrition']}
            layers={[
              CustomVerticalGrid,
              'grid',
              'axes',
              'boxPlots',
              CustomAttritionLabels,
              'legends',
              'annotations',
            ]}
          />
        )}
      </CardContent>
      <Box display='flex' flexDirection='row' justifyContent='end'>
        <Button
          variant='contained'
          size='small'
          color={'neutral'}
          onClick={handleExport}
          disabled={loading || !exportEnabled}
        >
          {t('export')}
        </Button>
      </Box>
    </Card>
  );
};
