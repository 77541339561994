import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid-pro';
import { Box } from '@mui/material';

/**
 * A custom toolbar to be used in the `toolbar` slot of a `DataGridPro` component, which
 * exposes the individual buttons so they can be customised.
 */
export const CustomToolbar: React.FC = () => {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport
        csvOptions={{
          utf8WithBom: true,
        }}
      />
      <Box
        sx={{
          flex: 1,
        }}
      />
      <GridToolbarQuickFilter />
    </GridToolbarContainer>
  );
};
