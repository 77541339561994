import { FormGroup } from '~/components/Form/FormGroup';
import { LoadingSkeleton } from '~/components/LoadingSkeleton';
import { SaveError } from '~/components/Form/SaveError';
import { Dialog } from '~/components/Dialog';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { usePublishVLifeReportFormHook } from './usePublishVLifeReportForm';
import { VLifeReportFormState } from '~/__generated-asset__/graphql';

export const PublishVLifeReport: React.FC = () => {
  const {
    reportForm,
    schema,
    loading,
    deleteForm,
    showDelete,
    setShowDelete,
    handleConfirmDelete,
    deleteError,

    onCancel,
    onPublish,
    onSendForReview,
    setValues,
    saving,
    setShowConfirmPublish,
    showConfirmPublish,
    handleConfirmPublish,
    publishReportFormError,
  } = usePublishVLifeReportFormHook();
  const { t } = useTranslation('publish-v-life-report-form');

  if (loading) {
    return <LoadingSkeleton />;
  }
  if (reportForm?.vlifeReportForm?.state !== VLifeReportFormState.Approved) {
    return <strong>{t('report-not-found')}</strong>;
  }
  const publishing = showConfirmPublish === 'publish';
  return (
    <>
      <FormGroup
        data={schema}
        loading={false}
        onCancel={onCancel}
        onSubmit={onPublish}
        onAdditionalSubmit={onSendForReview}
        setValues={(values) => setValues(values)}
        saving={saving}
        extraFooterContent={
          <>
            <Button color='error' variant='contained' onClick={deleteForm}>
              {t('viper::delete')}
            </Button>
            <div
              style={{
                flexGrow: 1,
              }}
            />
          </>
        }
        submitButtonText={t('publish')}
        additionalSubmitButtonText={t('send-for-review')}
      />
      <Dialog
        open={showConfirmPublish !== undefined}
        onClose={() => setShowConfirmPublish(undefined)}
        title={publishing ? t('publish-report/title') : t('back-for-review/title')}
        content={publishing ? t('publish-report/content') : t('back-for-review/content')}
        confirmText={publishing ? t('viper::sign') : t('send-for-review')}
        cancelText={t('viper::cancel')}
        onConfirm={handleConfirmPublish}
        loading={saving}
      />
      <SaveError errorState={publishReportFormError}>{t('publish-report/error')}</SaveError>
      <Dialog
        open={showDelete}
        title={t('delete-report/title')}
        content={t('delete-report/content')}
        onClose={() => {
          setShowDelete(false);
        }}
        confirmText={t('viper::delete')}
        cancelText={t('viper::cancel')}
        confirmColor='error'
        onConfirm={handleConfirmDelete}
        loading={loading}
      />
      <SaveError errorState={deleteError}>{t('delete-report/error')}</SaveError>
      {/* TODO can we display a toast on another page? */}
    </>
  );
};
