import { useState, useEffect } from 'react';
import { LoadingSkeleton } from '~/components/LoadingSkeleton';
import {
  FormControlLabel,
  FormGroup,
  Checkbox,
  styled,
  Card,
  Stack,
  Button,
  Link,
  CardContent,
  CardActions,
  Snackbar,
  SnackbarContent,
  Typography,
} from '@mui/material';

import {
  CookiePreferenceGroups,
  getCookiePreferences,
  CookiePreferences,
  useCookiePreferences,
} from '~/common/useCookiePreferences';

import { useTranslation } from 'react-i18next';

const StyledCheckbox = styled(Checkbox)`
  ${({ theme }) =>
    `&.Mui-checked {
    color: black;
  }
  &.Mui-disabled {
    &.Mui-checked {
      color: ${theme.palette.grey[600]}};
    }
  }
`}
`;

export const UserProfile: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation('user-profile');

  const [open, setOpen] = useState(false);

  const { onAcceptCookiePreferences } = useCookiePreferences();

  const [preferences, setPreferences] = useState<CookiePreferences>({
    essential: false,
    performance: false,
    functionality: false,
    marketing: false,
  });

  useEffect(() => {
    const existingPreferences = getCookiePreferences();
    setLoading(false);
    if (existingPreferences !== undefined) {
      setPreferences(existingPreferences);
    }
  }, []);

  const updateSettings = (key: CookiePreferenceGroups) => {
    setPreferences((settings: CookiePreferences) => ({ ...settings, [key]: !settings[key] }));
  };

  const handleClose = () => {
    setOpen(!open);
  };

  const updateAndSet = (type: boolean) => {
    const updatedSettings = {
      essential: type,
      performance: type,
      functionality: type,
      marketing: type,
    };
    setPreferences(updatedSettings);
    onAcceptCookiePreferences(updatedSettings);
    setOpen(!open);
  };

  if (loading) {
    return <LoadingSkeleton />;
  }

  const checkboxOptions = [
    {
      key: 'necessary',
      label: t('label/necessary'),
      checked: true,
      disabled: true,
    },
    {
      key: 'performance',
      label: t('label/performance'),
      checked: preferences.performance,
      onChange: () => updateSettings(CookiePreferenceGroups.PERFORMANCE),
    },
    {
      key: 'functionality',
      label: t('label/functionality'),
      checked: preferences.functionality,
      onChange: () => updateSettings(CookiePreferenceGroups.FUNCTIONALITY),
    },
    {
      key: 'marketing',
      label: t('label/marketing'),
      checked: preferences.marketing,
      onChange: () => updateSettings(CookiePreferenceGroups.MARKETING),
    },
  ];

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={open}
        autoHideDuration={4000}
        onClose={handleClose}
      >
        <SnackbarContent style={{ justifyContent: 'center' }} message='Cookies updated' />
      </Snackbar>
      <Card>
        <CardContent>
          <Typography>
            {t('text')}
            <Link target='blank' href={`${t('url')}`}>
              {t('link')}
            </Link>
          </Typography>
          <FormGroup>
            {checkboxOptions.map(({ key, label, checked, onChange, disabled }) => (
              <FormControlLabel
                key={key}
                disabled={disabled}
                checked={checked}
                onChange={onChange}
                control={<StyledCheckbox />}
                label={label}
                sx={
                  disabled
                    ? {
                        '& .MuiFormControlLabel-label.Mui-disabled': {
                          color: (theme) => theme.palette.grey[600],
                        },
                      }
                    : {}
                }
              />
            ))}
          </FormGroup>
        </CardContent>
        <CardActions sx={{ backgroundColor: (theme) => theme.palette.grey[300] }}>
          <Stack direction='row' spacing={2} m={1} justifyContent='flex-end' width='100%'>
            <Button variant='contained' color='secondary' onClick={() => updateAndSet(false)}>
              {t('button/reject')}
            </Button>
            <Button
              variant='contained'
              color='secondary'
              onClick={() => {
                onAcceptCookiePreferences(preferences);
                setOpen(!open);
              }}
            >
              {t('button/accept')}
            </Button>
            <Button variant='contained' color='secondary' onClick={() => updateAndSet(true)}>
              {t('button/accept-all')}
            </Button>
          </Stack>
        </CardActions>
      </Card>
    </>
  );
};
