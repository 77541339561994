import { AllEnvironmentVariables } from '~/config/types';

export class EnvironmentVariables implements AllEnvironmentVariables {
  private get = (variableName: keyof AllEnvironmentVariables) => {
    const windowEnv = window.env ?? {};
    const importedValue = `${windowEnv[variableName] ?? ''}`;
    const processEnvValue = `${process.env[variableName] ?? ''}`;

    if (importedValue.length) {
      return importedValue;
    }
    if (processEnvValue.length) {
      return processEnvValue;
    }
    return '';
  };

  validate = () => {
    this.checkExistsOrThrow('NODE_ENV');
    this.checkExistsOrThrow('REACT_APP_TENANT_ID');
    this.checkExistsOrThrow('REACT_APP_CLIENT_ID');
    this.checkExistsOrThrow('REACT_APP_API_CLIENT_ID');
    this.checkExistsOrThrow('REACT_APP_ASSET_API_ENDPOINT');
    this.checkExistsOrThrow('REACT_APP_MEASUREMENT_API_ENDPOINT');
    this.checkExistsOrThrow('REACT_APP_DATA_RETRIEVAL_API_ENDPOINT');
    this.checkExistsOrThrow('REACT_APP_APPLICATIONINSIGHTS_CONNECTION_STRING');
    this.checkExistsOrThrow('REACT_APP_APPLICATIONINSIGHTS_CORRELATION_ID');
    this.checkExistsOrThrow('REACT_APP_APPLICATIONINSIGHTS_SAMPLING_PERCENTAGE');
    this.checkExistsOrThrow('REACT_APP_MUI_X_LICENCE_KEY');
  };

  error = (errorMessage: string) => {
    if (this.NODE_ENV !== 'test') {
      throw Error(errorMessage);
    }
  };

  checkExistsOrThrow = (variableName: keyof AllEnvironmentVariables) => {
    const value = this[variableName];
    if (!value || `${value}`.length === 0) {
      this.error(`No value has been provided for environment variable ${variableName}`);
    }
  };

  get NODE_ENV() {
    return this.get('NODE_ENV');
  }
  get REACT_APP_TENANT_ID() {
    return this.get('REACT_APP_TENANT_ID');
  }
  get REACT_APP_CLIENT_ID() {
    return this.get('REACT_APP_CLIENT_ID');
  }
  get REACT_APP_API_CLIENT_ID() {
    return this.get('REACT_APP_API_CLIENT_ID');
  }
  get REACT_APP_ASSET_API_ENDPOINT() {
    return this.get('REACT_APP_ASSET_API_ENDPOINT');
  }
  get REACT_APP_MEASUREMENT_API_ENDPOINT() {
    return this.get('REACT_APP_MEASUREMENT_API_ENDPOINT');
  }
  get REACT_APP_DATA_RETRIEVAL_API_ENDPOINT() {
    return this.get('REACT_APP_DATA_RETRIEVAL_API_ENDPOINT');
  }
  get REACT_APP_APPLICATIONINSIGHTS_CONNECTION_STRING() {
    return this.get('REACT_APP_APPLICATIONINSIGHTS_CONNECTION_STRING');
  }
  get REACT_APP_APPLICATIONINSIGHTS_SAMPLING_PERCENTAGE() {
    return this.get('REACT_APP_APPLICATIONINSIGHTS_SAMPLING_PERCENTAGE');
  }
  get REACT_APP_APPLICATIONINSIGHTS_CORRELATION_ID() {
    return this.get('REACT_APP_APPLICATIONINSIGHTS_CORRELATION_ID');
  }
  get REACT_APP_MUI_X_LICENCE_KEY() {
    return this.get('REACT_APP_MUI_X_LICENCE_KEY');
  }
}

export const env = new EnvironmentVariables();
