import { useDataRetrievalApolloClient } from '~/common/graphql-data-retrieval';
import { GetVlimsDocument } from '~/__generated-data-retrieval__/graphql';
import { useQuery } from '@apollo/client';

export const useGetVlims = () => {
  const client = useDataRetrievalApolloClient();

  return useQuery(GetVlimsDocument, {
    client,
    fetchPolicy: 'no-cache',
  });
};
