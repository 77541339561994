import { Input } from '@mui/base/Input';
import { InputBase, styled } from '@mui/material';
import React from 'react';
export const StyledInput = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.grey[500]}`,
    fontSize: 16,
    padding: '4px 24px 4px 8px',
    '&:focus': {
      borderRadius: 4,
      borderColor: theme.palette.grey[500],
      boxShadow: `0 0 0 1px ${theme.palette.grey[500]}`,
    },
    flex: '1 1 100%',
  },
}));

export const CustomInput = React.forwardRef(function CustomInput(
  props: React.InputHTMLAttributes<HTMLInputElement> & {
    InputProps?: React.ComponentProps<typeof Input>;
  },
  ref: React.ForwardedRef<HTMLInputElement>
) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars -- InputProps is not a valid prop for InputBase
  const { InputProps, ...rest } = props;

  return (
    <Input
      slots={{ input: StyledInput }}
      slotProps={{
        input: {
          ref,
          ...rest,
        },
      }}
      {...rest}
    />
  );
});
