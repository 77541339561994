import React from 'react';
import { Button } from '@mui/material';

export const SelectButton: React.FC<{
  label: string;
  selected: boolean;
  onClick: () => void;
}> = ({ label, selected, onClick }) => {
  return (
    <Button
      variant='contained'
      color='neutral'
      aria-pressed={selected}
      onClick={onClick}
      sx={{
        background: (theme) => theme.palette.grey[300],
        '&:hover': {
          background: (theme) => theme.palette.grey[400],
        },
        '&[aria-pressed="true"]': {
          background: (theme) => theme.palette.grey[400],
        },
      }}
    >
      {label}
    </Button>
  );
};
