import React from 'react';
import { Box, Button } from '@mui/material';
import { ActionContext } from './ActionProvider';

export const RenderActions: React.FC = () => {
  const actions = React.useContext(ActionContext);
  return (
    <Box display='flex' height='100%' alignItems='center'>
      {actions.actions.map((action, index) => (
        <Button
          sx={{ marginLeft: '1rem' }}
          key={index}
          onClick={action.onClick}
          variant={action.variant || 'contained'}
          color={action.color || 'secondary'}
        >
          {action.label}
        </Button>
      ))}
    </Box>
  );
};
