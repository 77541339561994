import { styled, Tooltip as MuiTooltip, TooltipProps, tooltipClasses } from '@mui/material';

export const Tooltip = styled(({ className, ...props }: TooltipProps) => (
  <MuiTooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: '0.25rem 0.5rem',
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[3],
    fontSize: 12,
    color: theme.palette.text.primary,
    fontWeight: 400,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
  },
}));
