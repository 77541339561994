import React from 'react';
import { DefaultRawDatum, ResponsivePie, PieTooltipProps } from '@nivo/pie';
import { defaultColors, pieTheme } from './defaults';

export const NivoPie: React.FC<{
  data?: DefaultRawDatum[];
  colors?: string[];
  tooltip?: React.FC<PieTooltipProps<DefaultRawDatum>>;
  arcLabel?: (item: DefaultRawDatum) => string;
}> = ({ data, colors = defaultColors, tooltip, arcLabel }) => {
  return (
    <ResponsivePie
      data={data ?? []}
      theme={pieTheme}
      margin={{ top: 10, bottom: 10 }}
      activeOuterRadiusOffset={8}
      animate={true}
      enableArcLabels={!!arcLabel}
      enableArcLinkLabels={false}
      colors={colors}
      tooltip={tooltip}
      arcLabel={arcLabel}
      sortByValue
    />
  );
};
