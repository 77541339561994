import { GRID_CHECKBOX_SELECTION_COL_DEF, GridColDef } from '@mui/x-data-grid-pro';
import { useMemo } from 'react';
import { IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import { Clear } from '@mui/icons-material';
import { useMessageListColumns } from '~/pages/VLifeReports/useMessageListColumns';
import { VLifeReportLogMessage } from '~/__generated-asset__/graphql';

interface UseSelectMessagesColumnsProps {
  rowDeletionCallback: (row: VLifeReportLogMessage) => void;
}

export function useSelectMessagesColumns({
  rowDeletionCallback,
}: UseSelectMessagesColumnsProps) {
  const { columns } = useMessageListColumns();

  const columnsWithDelete: GridColDef[] = useMemo(
    () => [
      ...columns,
      {
        field: 'action',
        headerName: '',
        minWidth: 70,
        maxWidth: 70,
        renderCell: ({ row }) => (
          <Box sx={{ width: '100%', textAlign: 'right', my: -0.5 }}>
            <IconButton onClick={() => rowDeletionCallback(row)} size='small'>
              <Clear sx={{ fontSize: '16px' }} />
            </IconButton>
          </Box>
        ),
      },
    ],
    [columns, rowDeletionCallback]
  );

  const columnsWithSelect = [...columns, GRID_CHECKBOX_SELECTION_COL_DEF];

  return { columns, columnsWithDelete, columnsWithSelect };
}
