import { useCallback, useState } from 'react';
import domToImage, { Options } from 'dom-to-image';
import { useTheme } from '@mui/material';

export const useExportDomElementToPng = (
  domElementRef: React.RefObject<HTMLElement>,
  filename = 'export.png',
  optionOverrides?: Options
) => {
  const [exportEnabled, setExportEnabled] = useState<boolean>(true);
  const theme = useTheme();

  const handleExport = useCallback(async () => {
    if (exportEnabled && domElementRef.current) {
      setExportEnabled(false);
      try {
        const dataUrl = await domToImage.toPng(domElementRef.current, {
          bgcolor: theme.palette.background.paper,
          width: domElementRef.current.offsetWidth,
          height: domElementRef.current.offsetHeight,
          ...optionOverrides,
        });
        const link = document.createElement('a');
        link.download = filename;
        link.href = dataUrl;
        link.click();
      } catch (error) {
        console.error('oops, something went wrong!', error);
      } finally {
        setExportEnabled(true);
      }
    }
  }, [
    exportEnabled,
    domElementRef,
    theme.palette.background.paper,
    optionOverrides,
    filename,
  ]);

  return {
    handleExport,
    exportEnabled,
  };
};
