import { useMemo } from 'react';
import { GridColDef } from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';
import { LifecyclePill } from './LifecyclePill';
import { useNegatableFilterOperators } from '~/components/DataGrid/filters/filter-operators';
import { dateFormatter } from '~/components/DataGrid/value-formatters';

const baseColumn: Partial<GridColDef> = {
  minWidth: 200,
  maxWidth: 350,
  flex: 1,
};
export function useViperAssetColumns() {
  const { t } = useTranslation('viper-assets');
  const {
    negatableNumberFilterOperators,
    negatableDateFilterOperators,
    negatableStringFilterOperators,
    negatableBooleanFilterOperators,
  } = useNegatableFilterOperators();

  const columns: GridColDef[] = useMemo(
    () => [
      {
        ...baseColumn,
        field: 'id',
        headerName: t('id'),
        minWidth: 50,
        maxWidth: 100,
        filterOperators: negatableNumberFilterOperators,
      },
      {
        ...baseColumn,
        headerName: t('serial-number'),
        field: 'serialNumber',
        filterOperators: negatableNumberFilterOperators,
      },
      {
        ...baseColumn,
        headerName: t('part-name'),
        field: 'part.name',
        filterOperators: negatableStringFilterOperators,
      },
      {
        ...baseColumn,
        headerName: t('manufacturer'),
        field: 'manufacturer.name',
        filterOperators: negatableStringFilterOperators,
      },
      {
        ...baseColumn,
        headerName: t('date-of-manufacture'),
        field: 'dateOfManufacture',
        type: 'date',
        valueFormatter: dateFormatter,
        filterOperators: negatableDateFilterOperators,
      },
      {
        ...baseColumn,
        headerName: t('bom-revision'),
        field: 'bomRevision.name',
        filterOperators: negatableStringFilterOperators,
      },
      {
        ...baseColumn,
        headerName: t('primary-comms'),
        field: 'primaryComms.name',
        filterOperators: negatableStringFilterOperators,
      },
      {
        ...baseColumn,
        headerName: t('current-firmware-revision'),
        field: 'currentFirmwareRevision.name',
        filterOperators: negatableStringFilterOperators,
      },
      {
        ...baseColumn,
        headerName: t('ip-address'),
        field: 'ipAddress',
        filterOperators: negatableStringFilterOperators,
      },
      {
        ...baseColumn,
        headerName: t('ip-assignment-method'),
        field: 'ipAssignmentMethod.name',
        filterOperators: negatableStringFilterOperators,
      },
      {
        ...baseColumn,
        headerName: t('mac-address'),
        field: 'macAddress',
        filterOperators: negatableStringFilterOperators,
      },
      {
        ...baseColumn,
        headerName: t('admin-password'),
        field: 'adminPassword',
        filterOperators: negatableStringFilterOperators,
      },
      {
        ...baseColumn,
        headerName: t('root-revision-password'),
        field: 'rootRevisionPassword.name',
        filterOperators: negatableStringFilterOperators,
      },
      {
        ...baseColumn,
        headerName: t('max-ir-cap'),
        field: 'maxIrCap',
        filterOperators: negatableNumberFilterOperators,
      },
      {
        ...baseColumn,
        headerName: t('remote-access'),
        field: 'remoteAccess',
        filterOperators: negatableBooleanFilterOperators,
      },
      {
        ...baseColumn,
        headerName: t('polling-ip-address'),
        field: 'pollingIpAddress',
        filterOperators: negatableBooleanFilterOperators,
      },
      {
        ...baseColumn,
        headerName: t('date-of-purchase'),
        field: 'dateOfPurchase',
        type: 'date',
        valueFormatter: dateFormatter,
        filterOperators: negatableDateFilterOperators,
      },
      {
        ...baseColumn,
        headerName: t('end-client'),
        field: 'endClient.name',
        filterOperators: negatableStringFilterOperators,
      },
      {
        ...baseColumn,
        headerName: t('customer'),
        field: 'customer.name',
        filterOperators: negatableStringFilterOperators,
      },
      {
        ...baseColumn,
        headerName: t('general-notes'),
        field: 'generalNotes',
        filterOperators: negatableStringFilterOperators,
      },
      {
        ...baseColumn,
        headerName: t('status'),
        field: 'latestLifecycleEvent.eventType',
        renderCell: (params) => <LifecyclePill eventType={params.value} />,
        filterOperators: negatableStringFilterOperators,
      },
      {
        ...baseColumn,
        headerName: t('latest-installation-date'),
        field: 'latestInstallationEvent.dateOfLifecycleEvent',
        type: 'date',
        valueFormatter: dateFormatter,
        filterOperators: negatableDateFilterOperators,
      },
    ],
    [
      t,
      negatableNumberFilterOperators,
      negatableStringFilterOperators,
      negatableDateFilterOperators,
      negatableBooleanFilterOperators,
    ]
  );

  return { columns };
}
